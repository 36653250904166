import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LocationsList from '../components/pages/LocationsList';
import SEO from '../components/seo';
import '../styles/app.scss';

const Locations = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section
                className="page-section bg-secondary text-white"
                id="locgreen"
            >
                <div className="container smallestwdt">
                      {/* <h2 className="yellowtxt">
                        <span>122 Destinations</span>
                        <span className="commaloc">,</span>{' '}
                        <span>27 States</span>
                        <span className="commaloc">,</span>{' '}
                        <span>8 Countries</span>
                    </h2> */}
                    <h2 className="yellowtxt locnumbers"
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.locations_title,
                                }}
                          />
                    <h4>
                        We strive to create the best sensory gym for autism and
                        provide the best indoor playground franchise opportunity
                        available. Here at WRTS, we want your gym to be THE KIDS
                        GYM in your city.
                        <br />
                        Let’s grow your play franchise together.
                    </h4>
                </div>
            </section>
        <LocationsList
                locationsUsa={post.acf.list_locations_usa}
                locationsUsaCs={post.acf.list_locations_coming_soon_to_usa}
                locationsInt={post.acf.list_locations_international}
                locationsIntCs={
                    post.acf.list_locations_coming_soon_international
                }
            /> 
            {/* <section className='parent-iframe'>
            <iframe id='my-locationframe'
                 src="https://www.wrtsfranchise.com/our-locations"
                 className="wrts-global-locations"
                /> 
            </section> */}
            <Footer />
        </>
    );
};

export default Locations;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                locations_title
                list_locations_usa {
                    visit_website_url
                    view_photos_url
                    view_on_map_url
                    location_name
                    location_info
                    contact_us_url
                    location_image_usa {
                        localFile {
                            childImageSharp {
                                fluid {
                                    srcWebp
                                    src
                                }
                            }
                        }
                    }
                }
                list_locations_international {
                    visit_website_url
                    view_photos_url
                    view_on_map_url
                    location_name
                    location_info
                    contact_us_url
                    location_image_int {
                        localFile {
                            childImageSharp {
                                fluid {
                                    srcWebp
                                    src
                                }
                            }
                        }
                    }
                }
                list_locations_coming_soon_to_usa {
                    contact_us_url
                    location_info
                    location_name
                    view_on_map_url
                    view_photos_url
                    visit_website_url
                    location_image_c_usa {
                        localFile {
                            childImageSharp {
                                fluid {
                                    srcWebp
                                    src
                                }
                            }
                        }
                    }
                }
                list_locations_coming_soon_international {
                    contact_us_url
                    visit_website_url
                    view_photos_url
                    view_on_map_url
                    location_name
                    location_info
                    location_image_c_int {
                        localFile {
                            childImageSharp {
                                fluid {
                                    srcWebp
                                    src
                                }
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
